let audioCtx: AudioContext | null = null
let analyser: AnalyserNode | null = null
let dataArray: Uint8Array | null = null

const elements = {
    player: document.getElementById('audioPlayer') as HTMLAudioElement,
    profile: document.getElementById('profile') as HTMLImageElement,
}

function setupAudio() {
    if (audioCtx) return // Already set up

    audioCtx = new (window.AudioContext || (window as any).webkitAudioContext)()
    analyser = audioCtx.createAnalyser()
    analyser.fftSize = 512

    const source = audioCtx.createMediaElementSource(elements.player)
    source.connect(analyser)
    analyser.connect(audioCtx.destination)

    dataArray = new Uint8Array(analyser.frequencyBinCount)
}

function updateImage() {
    if (!analyser || !dataArray) return

    analyser.getByteFrequencyData(dataArray)

    const average =
        dataArray.reduce((acc, val) => acc + val, 0) / dataArray.length
    const scale = 0.5 + (average / 255) * 0.75
    const opacity = 0.15 + (average / 255) * 0.95

    elements.profile.style.transform = `scale(${scale})`
    elements.profile.style.opacity = opacity.toString()

    requestAnimationFrame(updateImage)
}

function initializeAudio() {
    setupAudio()
    if (audioCtx) {
        audioCtx.resume().then(() => {
            elements.player.play()
            updateImage()
        })
    }
}

function profileDance() {
    let isInitialized = false

    elements.profile.addEventListener('click', () => {
        if (!isInitialized) {
            initializeAudio()
            isInitialized = true
        } else {
            elements.player.muted = !elements.player.muted
        }
    })
}

// Start everything when the page loads
// window.addEventListener('load', profileDance)

// const exitBtn = document.querySelector('button.exit') as HTMLButtonElement
// exitBtn.addEventListener('click', () => WM.disconnect())

// console.log(WM.activeAddress)
